import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import thumbnailVoice from "../../static/images/voice.png"
import thumbnailComm from "../../static/images/communication.png"
import thumbnailPeople from "../../static/images/ancestors.png"

const AboutPage = () => (
    <Layout>
        <SEO title="About"/>

        <div className={"page-header"}>
            <h2>What’s Phonica?</h2>
            <p>Phonica is a secure, HIPAA-compliant digital platform that uses AI to help speech therapists track their clients’ progress and offer more engaging, effective, and personalized therapy.</p>
        </div>

        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Voice"} src={thumbnailVoice}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Anything</h2>
                                <p>We're using AI to evaluate fluency and identify specific speech impediments, allowing you to get the most comphrensive feedback possible.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Anytime, anywhere</h2>
                                <p>Clients are able to practice and record themselves at anytime and anywhere, combined with instantenous analysis and recommendations.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Communication"} src={thumbnailComm}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"People"} src={thumbnailPeople}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Anyone</h2>
                                <p>No individual is alike, so we're making sure our software works for every single one of your clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={"call-to-action"}>
            <div className={"container"}>
                
                <div className={"call-to-action__content"}>
                    <h2>Want to find out more?</h2>
                    <p>Contact us!</p>
                    <br />
                    <br />

                    <ContactForm />
                </div>
            </div>
        </div>
    </Layout>
)

export default AboutPage
