import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material'

import * as AWS from 'aws-sdk'

const configuration = {
    region: 'us-east-1',
    secretAccessKey: 'KzqlhR5+9Pd5eVrNhiRx5z54vBNhrWq8GMYu0CPR',
    accessKeyId: 'AKIASATGPACIYPQHUVWE'
}

AWS.config.update(configuration)

const docClient = new AWS.DynamoDB.DocumentClient()

export default function BasicTextFields() {
    const [name, set_name] = React.useState();
    const [email, set_email] = React.useState();
    const [message, set_message] = React.useState();

    return (
        <Box
            component="form"
            p={3}
            style={{
                background: 'white',
                width: '100%',
                borderRadius: 22
            }}
            onSubmit={() => {
                const params = {
                    TableName: 'ContactTable',
                    Item: {
                        name, email, message
                    }
                }
                docClient.put(params, console.log);
            }}
        >
            <TextField
                variant="standard"
                label='Name'
                required
                onChange={event => set_name(event.target.value)}
            />
            <br />
            <br />

            <TextField
                variant="standard"
                label='Email'
                type='email'
                required
                onChange={event => set_email(event.target.value)}
            />
            <br />
            <br />
            
            <TextField
                label='Message'
                type='email'
                multiline rows={9}
                fullWidth
                onChange={event => set_message(event.target.value)}
            />
            <br />
            <br />

            <Button
                variant='contained'
                type='submit'
                style={{
                    background: '#664fff',
                }}
            >
                Submit
            </Button>
        </Box>
    );
}
